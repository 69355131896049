import PropTypes from 'prop-types'
import React from 'react'
// import scrollTo from 'gatsby-plugin-smoothscroll'

import Header from './Header'
import Footer from './Footer'
import '../scss/gatstrap.scss'
import HeroSlider from './HeroSlider'
import useCookie from '../hooks/useCookie'
import { Link } from 'gatsby'

const CookieConsent = () => {
  const [cookieConsent, setCookieConsent] = useCookie('cookieConsentClosed')

  if (cookieConsent) return null
  return (
    <div className="cookie-consent">
      <p className="mr-5 mb-0">
        Diese Website setzt einen Cookie, der für den reibungslosen Betrieb der
        Seite erforderlich ist.{' '}
        <Link to="/impressum#datenschutz">-&gt; Datenschutz</Link>
      </p>
      <button
        className="btn btn-outline-light btn-sm"
        onClick={() => setCookieConsent('1')}
      >
        Schließen
      </button>
    </div>
  )
}

const Layout = ({
  children,
  location,
  contentOnly = false,
  showSlider = false,
  showFooter = true,
}) => {
  if (contentOnly === true) return <main>{children}</main>
  return (
    <div className="flex flex-column min-h-screen">
      <Header location={location} showSlider={showSlider} />
      {showSlider && <HeroSlider />}

      <main id="main-anchor" style={{ paddingTop: showSlider ? '0' : '70px' }}>
        {children}
      </main>
      {/* <button
        className="btn btn-rounded btn-scrolltop"
        onClick={() => scrollTo('#main-anchor')}
        >
        To top
      </button> */}
      {showFooter && <Footer location={location} />}
      <CookieConsent />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  contentOnly: PropTypes.bool,
  showSlider: PropTypes.bool,
  showFooter: PropTypes.bool,
  location: PropTypes.any,
}

export default Layout
