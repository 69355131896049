/* eslint-disable react/prop-types */
import React from 'react'
import { Link } from 'gatsby'
// import Img from 'gatsby-image'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import useToggle from '../hooks/useToggle'
import ksLogoWhite from '../assets/images/KUBOSHOW_Schriftzug_white.svg'
import ksLogoBrown from '../assets/images/KUBOSHOW_Schriftzug_brown.svg'

const iconSize = '24px'
export const linkClassNames = `nav-item nav-link mx-1 px-2`

const SocialMediaIcon = ({ link, entypoClass }) => (
  <a
    href={link}
    target="_blank"
    rel="noreferrer"
    style={{
      width: iconSize,
      height: iconSize,
      borderRadius: iconSize,
      border: '1px solid #666',
      display: 'inline-block',
    }}
    className="text-center ml-1"
  >
    <i
      className={entypoClass}
      style={{
        fontSize: '13px',
        color: '#666',
        lineHeight: iconSize,
        top: '-1px',
      }}
    />
  </a>
)
const SocialMedia = () => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <SocialMediaIcon
        entypoClass="entypo-instagram"
        link="https://instagram.com/kuboshow_kunstmesse/"
      />
      <SocialMediaIcon
        entypoClass="entypo-facebook"
        link="https://www.facebook.com/Kuboshow-Kunstmesse-1642193969357580/timeline/"
      />
    </div>
  )
}

const SavedAnchorLink = ({ pathname = undefined, anchor, label }) => {
  if (pathname !== '/')
    return (
      <Link className={linkClassNames} to={'/' + anchor}>
        {label}
      </Link>
    )
  return (
    <AnchorLink offset="100" href={anchor} className={linkClassNames}>
      {label}
    </AnchorLink>
  )
}

const NavLinks = ({ location }) => {
  return (
    <>
      <Link
        to="/"
        className={
          linkClassNames + ` ${location.pathname === '/' ? 'active' : ''}`
        }
      >
        Home
      </Link>
      {/* <SavedAnchorLink
        pathname={location?.pathname}
        label="Messe"
        anchor="#messe"
      /> */}
      <Link
        to="/gallery-overview"
        className={
          linkClassNames +
          ` ${location.pathname === '/gallery-overview' ? 'active' : ''}`
        }
      >
        Künstler
      </Link>
      <SavedAnchorLink
        pathname={location?.pathname}
        label="Wann, wo, wie?"
        anchor="#details"
      />
      <SavedAnchorLink
        pathname={location?.pathname}
        label="Teilnahme"
        anchor="#teilnahme"
      />
      <SavedAnchorLink
        pathname={location?.pathname}
        label="Kontakt"
        anchor="#kontakt"
      />
      {/* <Link
        to="/login"
        className={
          linkClassNames + ` ${location.pathname === '/login' ? 'active' : ''}`
        }
      >
        Künstler-Login
      </Link> */}
    </>
  )
}

const Header = ({ location, showSlider = false }) => {
  const [open, toggle] = useToggle(false)
  const [hasScrolled, setHasScrolled] = React.useState(false)

  const handleScroll = () => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 32) {
      setHasScrolled(true)
    } else {
      setHasScrolled(false)
    }
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <header
      className={`navbar w-100 ${
        !hasScrolled && showSlider ? 'navbar-transparent' : 'navbar-light'
      }`}
    >
      <div className="w-100 d-flex justify-content-center py-2 align-items-center">
        <button
          className={`d-md-none hamburger hamburger--spring ${
            open && 'is-active'
          }`}
          type="button"
          onClick={() => toggle()}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
        <div className="d-flex align-items-center">
          <Link to={'/'} className="navbar-brand mr-3">
            <img
              src={!hasScrolled && showSlider ? ksLogoWhite : ksLogoBrown}
              height="30px"
              alt="KUBOSHOW KUNSTMESSE"
            />
          </Link>
        </div>
        <ul className="nav d-none d-md-flex justify-content-center align-items-center text-uppercase ml-4">
          <NavLinks location={location} />
        </ul>
        <SocialMedia mobile={false} />
      </div>
      {open && (
        <div
          className={`w-100 pb-3 text-warning bg-white d-md-none d-flex flex-column align-items-start`}
          style={{
            borderBottom: '1px solid rgb(226,0,116)',
            position: 'absolute',
            top: '108px',
            left: '0px',
            zIndex: '100000',
          }}
        >
          <NavLinks location={location} />
          <hr />
          <SocialMedia mobile={true} />
        </div>
      )}
    </header>
  )
}

export default Header
