import React from 'react'
import { Link } from 'gatsby'
import { linkClassNames } from './Header'

const Footer = ({ location }) => (
  <div className="footer">
    <div className="container py-4">
      <div className="row ">
        <div className="col-12 text-center">
          <p className="h6">FÖRDERER</p>
        </div>
      </div>
      <div className="row align-items-center justify-content-center">
        <div className="col-4 col-md-2">
          <a target="_blank" rel="noreferrer" href="https://bloemeke-media.de/">
            <img
              className="w-100 h-auto"
              alt="Blömeke"
              src="https://dl3.pushbulletusercontent.com/hbm7bkWKpacl9d2ZQB5LNdlsye0nYWpR/bloemeke.jpg"
            />
          </a>
        </div>
        <div className="col-4 col-md-2">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.stadtwerke-herne.de/"
          >
            <img
              alt="Stadtwerke HERNE"
              className="w-100 h-auto"
              src="https://imgs.kuboshow.de/wp/LOGO_STWH_CMYK.png"
            />
          </a>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center mt-5">
        <div>{`© Copyright Der Ku UG ${new Date().getFullYear()} | `}</div>
        <nav className="nav">
          <Link
            to="/impressum#datenschutz"
            className={
              linkClassNames +
              ` ${location.pathname === '/datenschutz' ? 'active' : ''}`
            }
          >
            Datenschutz
          </Link>
          <Link
            to="/impressum"
            className={
              linkClassNames +
              ` ${location.pathname === '/impressum' ? 'active' : ''}`
            }
          >
            Impressum
          </Link>
        </nav>
      </div>
    </div>
  </div>
)

export default Footer
